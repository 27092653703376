<template>
  <div class="rootBox">
    <div class="searchOut boxStyle">
      <div class="searchParameterBox">
        <el-form
          :model="searchData"
          ref="searchForm"
          inline
          @submit.native.prevent
        >
          <el-form-item :label="$t('common.customerCode')" prop="name">
            <el-input
              v-model="searchData.customerCode"
              @keyup.native.enter="searchFun()"
              @change="searchContentChange"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonBox">
        <el-button size="small" type="primary" @click="searchFun()">{{
          $t("button.search")
        }}</el-button>
        <el-button size="small" @click="resetSearchForm('searchForm')">{{
          $t("button.reset")
        }}</el-button>
      </div>
    </div>

    <div class="contentBox boxStyle">
      <div class="operateOut">
        <p>{{ $t("certificateConfig.certificateList") }}</p>
        <el-button type="primary" size="small" @click="openDialog('add')">{{
          $t("button.add")
        }}</el-button>
      </div>
      <div class="tableBox">
        <el-table
          :data="tableData"
          stripe
          header-cell-class-name="tableThStyle"
        >
          <el-table-column
            prop="customerCode"
            :label="$t('common.customerCode')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="certificateName"
            :label="$t('certificateConfig.certificateName')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="storePass"
            :label="$t('certificateConfig.storepass')"
            align="center"
          >
            <template slot-scope="scope">{{
              scope.row.storePass ? "******" : ""
            }}</template>
          </el-table-column>
          <el-table-column
            prop="keyPass"
            :label="$t('certificateConfig.keypass')"
            align="center"
            show-overflow-tooltip
            ><template slot-scope="scope">{{
              scope.row.keyPass ? "******" : ""
            }}</template>
          </el-table-column>
          <el-table-column
            :label="$t('common.operate')"
            fixed="right"
            min-width="100px"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="blueTextBtn"
                @click="openDialog('edit', scope.row)"
                >{{ $t("button.edit") }}</el-button
              >
              <el-button
                type="text"
                class="redTextBtn"
                @click="deleteCertificate(scope.row)"
                >{{ $t("button.delete") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="paginationOut">
        <el-pagination
          background
          layout="prev, pager, next,jumper,sizes,total"
          popper-class="pageSizeSelect"
          :page-sizes="pagination.pageSizeOption"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          :current-page.sync="pagination.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      width="45%"
      :title="getTitle(this.btnType)"
      :visible.sync="dialogStatu.editCustomerInfo"
      custom-class="dialogStyle"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form
            label-position="left"
            :model="certificateForm"
            label-width="20%"
            ref="addOrEditForm"
            :rules="addOrEditFormRules"
          >
            <el-form-item
              :label="$t('common.customerCode')"
              prop="customerCode"
            >
              <el-input
                v-model.number="certificateForm.customerCode"
                :disabled="btnType == 'edit'"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('certificateConfig.certificate')"
              prop="certificate"
              required="required"
            >
              <el-upload
                drag
                action="fakeaction"
                :auto-upload="false"
                :show-file-list="true"
                :limit="1"
                :onChange="onChange"
                style="width: 100%"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item
              :label="$t('certificateConfig.fileName')"
              prop="fileName"
              v-if="btnType == 'edit'"
            >
              <el-input
                v-model.number="certificateForm.certificateName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('certificateConfig.keypass')"
              prop="keyPass"
            >
              <el-input
                v-model.number="certificateForm.keyPass"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('certificateConfig.storepass')"
              prop="storePass"
            >
              <el-input
                v-model.number="certificateForm.storePass"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogStatu.editCustomerInfo = false">{{
          $t("button.cancel")
        }}</el-button>
        <el-button class="blueBtn" type="primary" @click="addcertificat()">{{
          $t("button.confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from "@/mixins/mixins";
import _ from "lodash";
import CryptoJS from "crypto-js";
export default {
  mixins: [mixin],
  name: "",
  created() {
    this.searchFun();
  },
  data() {
    return {
      addOrEditFormRules: {
        customerCode: [{ required: "true", message: " ", trigger: "blur" }],
        storePass: [{ required: "true", message: " ", trigger: "blur" }],
        keyPass: [{ required: "true", message: " ", trigger: "blur" }],
      },
      searchData: {},
      tableData: [],
      btnType: "",
      dialogStatu: {
        editCustomerInfo: false,
      },
      certificateForm: {
        customerCode: "",
        certificate: "",
        keyPass: "",
        storePass: "",
        fileName: "",
      },
    };
  },
  methods: {
    //aes加密算法
    encrypt(word) {
      var key = CryptoJS.enc.Utf8.parse("59c432a830ac3cddd1b0013ae8d4e4e3");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return "{AES}" + encrypted.toString();
    },
    //解密
    decrypt(word) {
      if (word.startsWith("{AES}")) {
        word = word.substring(5);
        var key = CryptoJS.enc.Utf8.parse("59c432a830ac3cddd1b0013ae8d4e4e3");
        var decrypt = CryptoJS.AES.decrypt(word, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
      } else {
        return word;
      }
    },
    searchFun() {
      this.tableData = [];
      let submitInfo = {
        ...this.searchData,
      };
      return new Promise((resolve, reject) => {
        this.$httpList
          .getCertificates(submitInfo)
          .then((res) => {
            if (res.resultCode == 1001) {
              for (let key in res.data.certificateMap) {
                res.data.certificateMap[key].keyPass = this.decrypt(
                  res.data.certificateMap[key].keyPass
                );
                res.data.certificateMap[key].storePass = this.decrypt(
                  res.data.certificateMap[key].storePass
                );
                this.tableData.push(res.data.certificateMap[key]);
              }
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openDialog(btnType, row) {
      this.resetCertificateForm();
      this.btnType = btnType;
      if (btnType == "edit") {
        this.certificateForm = _.cloneDeep(row);
      }
      this.dialogStatu.editCustomerInfo = true;
    },
    onChange(file) {
      this.$notify({
        type: "success",
        message: "证书添加成功",
      });
      this.certificateForm.certificate = file.raw;
      this.certificateForm.fileName = file.name;
    },
    addCertificate() {
      this.certificateForm.certificates.push({
        customerCode: "",
        certificate: "",
        keyPass: "",
        storePass: "",
        fileName: "",
      });
    },
    submitForm() {
    },
    addcertificat() {

      let formDate = new FormData();
      formDate.append("type", "ADD");
      formDate.append("certificateName", this.certificateForm.fileName);
      formDate.append(
        "storePass",
        this.encrypt(this.certificateForm.storePass)
      );
      formDate.append("keyPass", this.encrypt(this.certificateForm.keyPass));
      formDate.append("customerCode", this.certificateForm.customerCode);
      formDate.append("certificate", this.certificateForm.certificate);
      // formDate.append("description",this.versionInfoForm.versionFunction);
      this.$refs["addOrEditForm"].validate((valid) => {
        if (valid) {
          return new Promise((resolve, reject) => {
            this.$httpList
              .addOrUpdateCertificate(formDate)
              .then((res) => {
                if (res.resultCode == 1001) {
                  this.$notify({
                    type: "success",
                    message: "证书信息上传成功",
                  });
                  this.dialogStatu.editCustomerInfo = false;
                }

                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
        }
      });
    },
    deleteCertificate(data) {
      let submitInfo = {
        customerCode: data.customerCode,
        certificateName: data.certificateName,
      };
      return new Promise((resolve, reject) => {
        this.$httpList
          .deleteCertificate(submitInfo)
          .then((res) => {
            this.searchFun();
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetCertificateForm() {
      this.certificateForm = {
        customerCode: "",
        certificate: "",
        keyPass: "",
        storePass: "",
        fileName: "",
      };
    },
    getTitle(btnType) {
      let title = "";
      if (btnType == "add") {
        title = this.$t("certificateConfig.createCertificate");
      } else if (btnType == "edit") {
        title = this.$t("certificateConfig.editCertificate");
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload {
  width: 100%;
}
/deep/ .el-upload .el-upload-dragger {
  width: 100%;
}
.formOut {
  display: flex;
  // max-width: 100%
  height: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  .certificate {
    min-width: 60%;
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    // box-shadow: 1px 1px 5px #888888;
    // align-items: center;
    .certificateBox {
      // border: 1px solid black;
      border-radius: 10px;
      padding: 10px;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 2px;
      box-shadow: 1px 1px 5px #888888;
      // text-align: left;
    }
  }
}
</style>
