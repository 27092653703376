const mixin = {
  data() {
    return {
      searchOpen: false,

      searchConChange: false,
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizeOption: [10, 20, 30, 50]
      }
    };
  },

  methods: {
    handleSizeChange() {
      this.pagination.currentPage = 1;
      this.searchData.start =
        (this.pagination.currentPage - 1) * this.pagination.pageSize;
      this.searchData.count = this.pagination.pageSize;
      this.searchFun();
    },

    handleCurrentChange() {
      this.searchData.start =
        (this.pagination.currentPage - 1) * this.pagination.pageSize;
      this.searchData.count = this.pagination.pageSize;
      this.searchFun();
    },

    searchContentChange() {
      this.searchConChange = true;
    },

    changeOpen() {
      this.searchOpen = !this.searchOpen;
    },

    //重置查询
    resetSearch() {
      this.searchData = {};
      this.pagination = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizeOption: [10, 20, 30, 50]
      };
      this.searchFun();
    }
  }
};

export default mixin;
